import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "next/image";
import RobotImage from "../../public/images/robotHead.png";
import { db } from "../../components/utils/firebase/firebase";
import DataContext from "../../context/dataContext";
import { useContext } from "react";
import { updateDoc, doc } from "firebase/firestore";

const ToggleSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f1f1f150;
  transition: 0.4s;
  border-radius: 34px;
  //opacity: ${(props) => (props.isOn ? "1" : "0.5")};

  &:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: ${(props) => (props.isOn ? "5px" : "-2px")};
    bottom: -7px;
    background-color: ${(props) => (props.isOn ? "#404040" : "#ffffff")};;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const RobotImageWrapper = styled.div`
  position: relative;
  width: 23px;
  height: 23px;
  left:10px;
  bottom: 1px;
  transition: transform linear  200ms;
  opacity: ${(props) => (props.isOn ? "1" : "0")};
  transform: ${(props) => (props.isOn ? "translateX(26px)" : "none")};
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  display: none;

  &:checked + ${Slider} {
    background-color: #13CBA4;
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px #13CBA4;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`;

const Label = styled.span`
  margin-left: 12px;
  color: ${(props) => (props.isOn ? "#f1f1f1" : "#ffffff90")};
  font-size: 1rem;
  font-weight: 400;
`;

const ToggleSwitch = ({ labelText, isOn }) => {

  const handleToggle = async () => {
    const docRef = doc(db, "clients/speed/tasks/autoValues")
    await updateDoc(docRef, { autoQueues: !isOn })
  };

  return (
    <ToggleSwitchContainer>
      <Switch>
        <Checkbox checked={isOn} onChange={handleToggle} />
        <Slider isOn={isOn} />
        <RobotImageWrapper isOn={isOn}>
          <Image src={RobotImage} alt="Robot" layout="fill" objectFit="contain" />
        </RobotImageWrapper>
      </Switch>
      <Label isOn={isOn}>{labelText}</Label>
    </ToggleSwitchContainer>
  );
};

export default ToggleSwitch;
