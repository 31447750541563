import React, { useEffect, useState } from 'react';
import Toggle from '../../components/utils/Toggle';
import TixyLogo from '../partials/tixyLogo';
import { ButtonsInner, HeaderContainer, HeaderInner, LogoDiv } from './NavBarAdmin';

export default function NavbarBasic({}) {
    const [scroll, setScroll] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScroll(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    //console.log(scroll)

    return (
        <>
            <HeaderContainer background={scroll > 100 ? backgroundColor : "transparent"} >
                <HeaderInner>
                    <LogoDiv>
                        <TixyLogo />
                    </LogoDiv>
                    <ButtonsInner>
                        <Toggle />
                    </ButtonsInner>
                </HeaderInner>
            </HeaderContainer>
        </>
    );


}
