import { Squash as Hamburger } from 'hamburger-react';
import Link from 'next/link';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import LanguageContext from '../../context/languageContext';
import { sidebarItems } from './sidebarItems';
import SignOutButton from './signOutButton';
import ToggleButtonNotEmpty from '../../components/utils/Toggle';

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  
  align-items: center;
  position: fixed;
  box-sizing: border-box;
  min-height: 100%;
  top:0;
  z-index: 100;
  transition: linear 300ms;
  //background: #0D1016;
  background-color: #1A1A1B;
  gap: 20px;
  margin-top: 0px;
  padding-top: 150px;

  width: ${({ sidebar }) => (sidebar ? '185px' : '55px')};
  left: ${({ sidebar, desktop }) => (desktop || sidebar ? '0px' : '-250px')};


  @media screen and (max-width:700px) {
    padding-top: 50px;
  }
`;

const TilesIcon = styled.div`
    font-size: ${({ sidebar }) => (sidebar ? '1.2rem' : '2rem')};
    fill:1;
    color:#F9F7F5;
`;

const TilesText = styled.span`
    font-size: ${({ sidebar }) => (sidebar ? '1.2rem' : '0rem')};;
    color:#F9F7F5;
    text-align: left;
    transition: font-size 450ms;
`;

const NavMenu = styled.div`
  display: flex;
  color: #17cba4;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;
  text-decoration: none;
  margin-bottom: 10px;
`;

const InnerNav = styled.div`
  display: flex;
  color: #17cba4;
  flex-direction: row;
  justify-content: ${({ sidebar }) => (sidebar ? 'flex-start' : 'center')};
  align-items: center;
  box-sizing: border-box;
  height: 90%;
  width: 90%;
  text-decoration: none;
  gap: ${({ sidebar }) => (sidebar ? '10px' : '0px')};
  border-radius: 5px;
  padding: ${({ sidebar }) => (sidebar ? '0px 5px 0px 8px' : '0px')};

  &:hover {
    background-color: #ffffff50;
    cursor: pointer;
  }

`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  padding-bottom: 50px;
`;

const HamburgerMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 1.6rem;
    width:55px;
    height:55px;
    color:#F9F7F5;
    z-index: 1000;
    padding-top: 10px;
    z-index: 101;
    &:hover {
        cursor: pointer;
    }
`;

const TooggleMenu = styled.div`
    width:100%;
    height:55px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
   flex-direction: row;
   padding-left: 20px;
`;


function Sidebar({ currentUser }) {
  const [width, setWidth] = useState(0);
  const [isOpen, setOpen] = useState(false)
  const { language } = useContext(LanguageContext)
  const items = sidebarItems(currentUser.uid, language)

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth)
    });

  }, []);

  return (
    <div>
      <HamburgerMenu>
        <Hamburger toggled={isOpen} toggle={setOpen} size={26} />
      </HamburgerMenu>
      <SidebarNav sidebar={isOpen} desktop={width > 1500}>

        {
          items.map((item, index) => {
            return (
              <Link href={item.link} key={index} >
                <NavMenu onClick={() => setOpen(false)} >
                  <InnerNav sidebar={isOpen}>
                    <TilesIcon>
                      {item.icon}
                    </TilesIcon>
                    <TilesText sidebar={isOpen}>
                      {item.title}
                    </TilesText>
                  </InnerNav>
                </NavMenu>
              </Link>
            )
          })
        }
        {isOpen && (<TooggleMenu> <ToggleButtonNotEmpty /> </TooggleMenu>)}
        <ButtonContainer>
          <SignOutButton sidebar={isOpen} />
        </ButtonContainer>
      </SidebarNav>
    </div>

  );
};

export default Sidebar;

//  width: ${({ sidebar }) => (sidebar ? '150px' : '50px')};
//           <SidebarMobile currentUser={currentUser} sidebar={isOpen} setSidebar={setSidebar} language={language}></SidebarMobile>

