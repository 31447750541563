import Home from '@mui/icons-material/Home';
import { BsFillBarChartFill, BsFillPeopleFill, BsSpeedometer2, BsLayersFill } from 'react-icons/bs';

export function sidebarItems(uid, language) {

    const pathArr = uid.split('-')
    return (
        [
            {
                title: language == 'swe' ? 'Verksamhet' : 'Overview',
                link: `/${pathArr[0]}/${pathArr[1]}`,
                icon: <BsSpeedometer2 />,
            },
            {
                title: language == 'swe' ? 'Statistik' : 'Statistics',
                link: `/${pathArr[0]}/${pathArr[1]}/workers`,
                icon: <BsFillPeopleFill />,
            },
            {
                title: language == 'swe' ? 'Översikt' : 'Dashboard',
                link: `/${pathArr[0]}/${pathArr[1]}/dashboard`,
                icon: <BsFillBarChartFill />,
            },
            {
                title: language == 'swe' ? 'Lager' : 'Warehouse',
                link: `/${pathArr[0]}/${pathArr[1]}/warehouse`,
                icon: <BsLayersFill sx={{ fontSize: 'inherit', m: 0, p: 0 }} />,
            },
            {
                title: language == 'swe' ? 'Hem' : 'Home',
                link: `/${pathArr[0]}/`,
                icon: <Home sx={{ fontSize: 'inherit', m: 0, p: 0 }} />,
            },

        ]
    )
} 