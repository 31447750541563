import React from 'react';
import Toggle from '../../components/utils/Toggle';
import TixyLogo from '../partials/tixyLogo';
import { ButtonsInner, HeaderContainer, HeaderInner, LogoDiv } from './NavBarAdmin';


export default function NavBarUser({ }) {

    return (
        <>
            <HeaderContainer background={"#0a0a0a"} borderColor={"#ffffff00"}>
                <HeaderInner>
                    <LogoDiv><TixyLogo /></LogoDiv>
                    <ButtonsInner>
                        <Toggle ></Toggle>
                    </ButtonsInner>
                </HeaderInner>
            </HeaderContainer>
        </>
    );


}
