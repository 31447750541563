import React from 'react';
import Image from 'next/image';
import logo from '../../public/algoflow.png' 
import Link from 'next/link';

export default function TixyLogo() {
  // https://hackernoon.imgix.net/images/0*3IFEy-hfoIpgFjBl.gif

  return (
    <Link href={'/'}>
      <Image
        //layout="responsive"
        //objectFit={'contain'}
        src={logo}
        alt={'AlgoFlow logo'}
        //priority
      />
    </Link>
  );
}

