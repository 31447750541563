import React, { useContext } from 'react';
import TixyLogo from '../partials/tixyLogo';
import LanguageContext from '../../context/languageContext';
import { useRouter } from 'next/router';
import { Divider, FooterContainer, LogoDiv, WebsiteRights, SignOutButton } from './Footer.elements';
import DataContext from '../../context/dataContext';

function Footer() {
  const router = useRouter()
  const { language, logout } = useContext(LanguageContext);
  const {currentUser} = useContext(DataContext)
  async function handleLogout() {
    logout()
    router.push(`/`)
  }

  return (
    <>
      <FooterContainer>
        <LogoDiv>
          <TixyLogo />
        </LogoDiv>
        <Divider />
        {
          currentUser?.uid.includes('admin') || router.pathname === '/' || !currentUser?.uid ?
            <WebsiteRights> {`©${new Date().getFullYear()} Tixy Ab All rights reserved`}</WebsiteRights>
            :
            <SignOutButton onClick={handleLogout}> {language == 'swe' ? 'Logga ut' : 'Sign out'}</SignOutButton>
        }

      </FooterContainer>
    </>
  );
}

export default Footer;