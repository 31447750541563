import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import styled from 'styled-components';
import DataContext from '../../context/dataContext';
import TixyLogo from '../partials/tixyLogo';
import AutoToggle from './NavSwitch';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: ${props => props.position ? props.position : "fixed"};
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${props => props.background};
  height: 64px;
  max-height: 64px;
  z-index: 99;
  transition: 0.8s linear;
  box-sizing: border-box;
`;

export const HeaderInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1460px;
`;

export const ButtonsInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width:auto;
  flex: 1;
  height:100%;
  gap:24px;

  @media screen and (max-width:1400px) {
    padding-right: 20px;
  }

`;

export const MiddleButton = styled.span`
    color: #F1F1F1;
    font-weight: 400;
    height: 1.2rem;
    width: auto;
    font-size: 1.05rem;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: #F1F1F190;
      border-bottom:0.5px solid #F1F1F190;
      cursor: pointer;
    }
`;

export const LogoDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  height: 40px;
  cursor: pointer;

  @media screen and (max-width:1400px) {
    margin-left: 15px;
  }

  @media screen and (max-width:700px) {
    width: 80px;
    height: 35px;
  }

`;

export default function NavbarAdmin({ }) {
  const router = useRouter()
  const { currentUser, autoValues } = useContext(DataContext)
  const uidPath = currentUser.uid.split('-')[0]

  return (
    <>
      <HeaderContainer borderColor={"#00000000"} background={"#00000000"} position={"absolute"}>
        <HeaderInner>
          {router.pathname.includes('/admin') ?
            <></>
            :
            <LogoDiv><TixyLogo /></LogoDiv>
          }
          <ButtonsInner>
            <AutoToggle labelText={"Köer"} isOn={autoValues.autoQueues} />
            {router.pathname.includes('/admin') ?
              null
              :
              <MiddleButton><Link href={`/${uidPath}/admin`} > Admin </Link></MiddleButton>
            }
          </ButtonsInner>
        </HeaderInner>
      </HeaderContainer>
    </>
  );
}
