import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { sxPropButton } from '../../components/utils/reuseable/SxProps';
import LanguageContext from '../../context/languageContext';
import IconButton from '@mui/material/IconButton';


function SignOutButton({ sidebar }) {
    const { logout, language } = useContext(LanguageContext);
    return (
        <>
            {
                sidebar ?
                    <Button
                        onClick={() => logout()}
                        loadingPosition={"end"}
                        sx={sxPropButton}
                        fullWidth
                        variant="contained"
                        endIcon={<ExitToAppIcon sx={{ fontSize: 18 }} />}
                    >
                        {language == 'swe' ? 'Logga ut' : 'Sign out'}
                    </Button>
                    :
                    <IconButton size='large' onClick={() => logout()} >
                        <ExitToAppIcon sx={{ width:35,height:35,  color:'white'}} />
                    </IconButton>
            }

        </>

    )
}

export default SignOutButton