import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import DataContext from "../context/dataContext";
import Footer from "./Footer/Footer1";
import NavbarAdmin from "./Header/NavBarAdmin";
import NavbarBasic from "./Header/NavbarBasic";
import NavBarUser from "./Header/NavbarUser";
import Sidebar from "./Sidebar/sidebarMain";

export default function Layout({ children }) {
  const { currentUser, authReady } = useContext(DataContext);
  const history = useRouter();
  const currentPath = history.pathname;
  const uid = currentUser ? currentUser.uid : '';
  const pathArr = uid?.split('-');

  useEffect(() => {
    if (!authReady) {
      return;
    }

    if (uid) {
      if (currentPath === '/login' || currentPath === '/contact' || currentPath == '/') {
        const path = pathArr.includes('admin') ? `${pathArr[0]}/${pathArr[1]}` : `${pathArr[0]}`;
        history.push(`/${path}`);
      }
    } else if (currentPath != '/' && currentPath != '/login' && currentPath != '/contact') {
      history.push('/');
    }
  }, [currentUser, authReady]);

  const UserAccessNav = () => {
    const uid = currentUser?.uid

    if (uid) {
      if (uid.includes('admin') && currentPath.includes('admin')) {
        return (
          <section className="contentSideBar">
            <NavbarAdmin />
            <Sidebar currentUser={currentUser} />
            {children}
            <Footer />
          </section>
        );
      } else if (uid.includes('admin')) {
        return (
          <section className="content">
            <NavbarAdmin />
            {children}
            <Footer />
          </section>
        )
      }
      else {
        return (<section className="content">
          <NavBarUser />
          {children}
          <Footer />
        </section>
        )
      }
    } else {
      return (
        <div className="contentBasic">
          <NavbarBasic />
          {children}
          <Footer />
        </div>
      )

    }
  }

   // User signed out trying to access any page
   if (!uid && currentPath != '/login' && currentPath != '/contact' && currentPath != '/' ) {
    return (
      <div id='globalLoader'>
        <img src={'https://samherbert.net/svg-loaders/svg-loaders/ball-triangle.svg'} alt='loading image'></img>
      </div>
    );
  }

  // User trying to access admin page for the user domain
  if (!uid.includes('admin') && currentPath.includes('admin') || !currentPath.includes(pathArr[0])) {
    return (
      <div id='globalLoader'>
        <img src={'https://samherbert.net/svg-loaders/svg-loaders/ball-triangle.svg'} alt='loading image'></img>
      </div>
    );
  }

  return (
    <>
      {UserAccessNav()}
    </>
  )



}
